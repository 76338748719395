<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Edit client</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link :to="`/clients/view/${client.id}`">
            <button class="button expanded">View client</button>
          </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <button
          @click="showAccountHandlers = true"
          class="button small">Account handlers</button>&nbsp;
        <button
          @click="showAddresses = true"
          class="button small">Addresses</button>&nbsp;
        <button
          @click="showBrands = true"
          class="button small">Brands</button>&nbsp;
        <button
          @click="showContacts = true"
          class="button small">Contacts</button>&nbsp;
        <button
          @click="showInvoiceParameters = true"
          class="button small">Invoicing</button>&nbsp;
        <router-link :to="`/clients/ratecard/${client.id}`">
          <button class="button small">Ratecard</button>&nbsp;
        </router-link>
        <button
          @click="showVeevas = true"
          class="button small">Veeva</button>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <form @submit.prevent="save">
          <label>Name
            <input
              aria-describedby="nameHelpText"
              name="name"
              placeholder="name"
              type="text"
              v-model="client.name">
          </label>
          <p
            class="help-text"
            id="nameHelpText">
            Please enter the client's name
          </p>
          <label class="mt-3">Short Name
            <input
              aria-describedby="shortNameHelpText"
              name="shortName"
              placeholder="short name"
              type="text"
              v-model="client.short_name">
          </label>
          <p
            class="help-text"
            id="shortNameHelpText">
            Please enter a three letter short name for the client. This will be used in job numbers.
          </p>
          <label class="mt-3">Telephone
            <input
              aria-describedby="telephoneHelpText"
              name="telephone"
              placeholder="telephone"
              type="text"
              v-model="client.telephone">
          </label>
          <p
            class="help-text"
            id="telephoneHelpText">
            Please enter the clients main telephone number.
          </p>
          <div class="pt-3">
            <button class="button">
              Save client
            </button>
          </div>
        </form>
      </div>
    </div>
    <AccountHandlers
      v-if="showAccountHandlers"
      @hideAccountHandlers="showAccountHandlers = false"
      @removeAccountHandler="removeAccountHandler"
      @addAccountHandler="addAccountHandler"
      :accountHandlers="client.users"
      :clientId="client.id" />
    <Addresses
      v-if="showAddresses"
      @hideAddresses="showAddresses = false"
      @deleteAddress="deleteAddress"
      @addAddress="addAddress"
      @updateAddress="updateAddress"
      :addresses="client.addresses"
      :clientId="client.id" />
    <Brands
      v-if="showBrands"
      @hideBrands="showBrands = false"
      @addBrand="addBrand"
      @updateBrand="updateBrand"
      @deleteBrand="deleteBrand"
      :brands="client.brands"
      :clientId="client.id" />
    <Contacts
      v-if="showContacts"
      @hideContacts="showContacts = false"
      @addContact="addContact"
      @updateContact="updateContact"
      @deleteContact="deleteContact"
      :contacts="client.contacts"
      :clientId="client.id" />
    <InvoiceParameters
      v-if="showInvoiceParameters"
      @hideParameters="showInvoiceParameters = false"
      @addParameter="addParameter"
      @updateParameter="updateInvoiceParameter"
      @deleteParameter="deleteInvoiceParameter"
      :invoice_parameters="client.invoice_parameters"
      :clientId="client.id" />
    <Message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
    <Veevas
      v-if="showVeevas"
      @hideVeevas="showVeevas = false"
      @addVeeva="addVeeva"
      @updateVeeva="updateVeeva"
      @deleteVeeva="deleteVeeva"
      :veevas="client.veeva_accounts"
      :clientId="client.id" />
  </div>
</template>

<script>
import AccountHandlers from '@/views/clients/components/AccountHandlers.vue';
import Addresses from '@/views/clients/components/Addresses.vue';
import Brands from '@/views/clients/components/Brands.vue';
import Contacts from '@/views/clients/components/Contacts.vue';
import InvoiceParameters from '@/views/clients/components/InvoiceParameters.vue';
import Message from '@/components/messages/Message.vue';
import Veevas from '@/views/clients/components/Veevas.vue';
import axios from '../../axios';

export default {
  name: 'ClientsEdit',
  components: {
    AccountHandlers,
    Addresses,
    Brands,
    Contacts,
    InvoiceParameters,
    Message,
    Veevas,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      client: {},
      message: '',
      showAccountHandlers: false,
      showAddresses: false,
      showBrands: false,
      showContacts: false,
      showInvoiceParameters: false,
      showMessage: false,
      showVeevas: false,
    };
  },
  methods: {
    addAddress(response) {
      this.client.addresses.push(response.address);
      this.showAddresses = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateAddress(result) {
      this.client.addresses.splice(result.index, 1, result.response.address);
      this.showAddresses = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteAddress(index) {
      this.client.addresses.splice(index, 1);
    },
    addAccountHandler(response) {
      this.client.users.push(response.user);
      this.showAccountHandlers = false;
      this.showMessage = true;
      this.message = response.message;
    },
    removeAccountHandler(index) {
      this.client.users.splice(index, 1);
    },
    addBrand(response) {
      this.client.brands.push(response.brand);
      this.showBrands = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateBrand(result) {
      this.client.brands.splice(result.index, 1, result.response.brand);
      this.showBrands = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteBrand(response) {
      this.client.brands.splice(response, 1);
    },
    addContact(response) {
      if (response.result === true) {
        this.client.contacts.push(response.contact);
      }
      this.showContacts = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateContact(result) {
      this.client.contacts.splice(result.index, 1, result.response.contact);
      this.showContacts = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteContact(response) {
      this.client.contacts.splice(response, 1);
    },
    addParameter(response) {
      this.client.invoice_parameters.push(response.invoice_parameter);
      this.showInvoiceParameters = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateInvoiceParameter(result) {
      this.client.invoice_parameters.splice(result.index, 1, result.response.invoice_parameter);
      this.showInvoiceParameters = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteInvoiceParameter(response) {
      this.client.invoice_parameters.splice(response, 1);
      this.showInvoiceParameters = false;
    },
    addVeeva(response) {
      this.client.veeva_accounts.push(response.veeva);
      this.showVeevas = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateVeeva(result) {
      this.client.veeva_accounts.splice(result.index, 1, result.response.veeva);
      this.showVeevas = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteVeeva(response) {
      this.client.veeva_accounts.splice(response, 1);
    },
    save() {
      const postData = {};
      postData.client = this.client;
      axios.post(`/clients/update.json?token=${this.token}`, postData)
        .then((response) => {
          this.client = response.data.client;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getClient() {
      const clientId = this.$route.params.id;
      axios.get(`/clients/get/${clientId}.json?token=${this.token}`)
        .then((response) => {
          this.client = response.data.client;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getClient();
    }, 100);
  },
};
</script>
