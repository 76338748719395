<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addContact && contactInEdit === -1">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Telephone</th>
            <th>Type</th>
            <th>Title</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(contact, index) in contacts" :key="`c_${index}`">
              <td>{{ contact.first_name }} {{ contact.last_name }}</td>
              <td>{{ contact.email }}</td>
              <td>{{ contact.telephone }}</td>
              <td>{{ contact.type }}</td>
              <td>{{ contact.title }}</td>
              <td class="text-right">
                <span
                  title="Edit contact"
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="contactInEdit = index, selectedContact = contact"
                  >edit</span>
                <span
                  title="Delete contact"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteContact(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addContact" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>First name</label>
        <input v-model="contact.first_name" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Last name</label>
        <input v-model="contact.last_name" type="text">
      </div>
    </div>
    <div v-if="addContact" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Email</label>
        <input v-model="contact.email" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Telephone</label>
        <input v-model="contact.telephone" type="text">
      </div>
    </div>
    <div v-if="addContact" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Type</label>
        <input v-model="contact.type" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Title</label>
        <input v-model="contact.title" type="text">
      </div>
    </div>
    <div v-if="contactInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>First name</label>
        <input v-model="selectedContact.first_name" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Last name</label>
        <input v-model="selectedContact.last_name" type="text">
      </div>
    </div>
    <div v-if="contactInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Email</label>
        <input v-model="selectedContact.email" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Telephone</label>
        <input v-model="selectedContact.telephone" type="text">
      </div>
    </div>
    <div v-if="contactInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Type</label>
        <input v-model="selectedContact.type" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Title</label>
        <input v-model="selectedContact.title" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          v-if="!addContact && contactInEdit === -1"
          @click="addContact = true"
          class="button small">Add</button>
        &nbsp;
        <button
          v-if="addContact"
          @click="saveContact"
          class="button small">Save</button>
        &nbsp;
        <button
          v-if="contactInEdit > -1"
          @click="updateContact"
          class="button small">Save changes</button>
        &nbsp;
        <button @click="$emit('hideContacts')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ClientsComponentsContacts',
  props: [
    'contacts',
    'clientId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addContact: false,
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        telephone: '',
        type: '',
        title: '',
      },
      contactInEdit: -1,
      selectedContact: {},
    };
  },
  methods: {
    saveContact() {
      const postData = {};
      postData.contact = this.contact;
      postData.contact.client_id = this.clientId;
      axios.post(`/contacts/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addContact', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateContact() {
      const postData = {};
      postData.contact = this.selectedContact;
      const contactId = this.selectedContact.id;
      axios.post(`/contacts/update/${contactId}.json?token=${this.token}`, postData)
        .then((response) => {
          const result = {};
          result.response = response.data;
          result.index = this.contactInEdit;
          this.$emit('updateContact', result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteContact(index) {
      const contactId = this.contacts[index].id;
      axios.get(`/contacts/delete/${contactId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('deleteContact', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
