<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x pb-5">
      <div class="cell small-12">
        <h4>Manage addresses</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addAddress && addressInEdit === -1">
          <thead>
            <th>Name</th>
            <th>Primary?</th>
            <th>Invoice?</th>
            <th>Address</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(address, index) in addresses" :key="`a_${index}`">
              <td>{{ address.name }}</td>
              <td>
                <span v-if="address.primary_address">Yes</span>
                <span v-else>No</span>
              </td>
              <td>
                <span v-if="address.invoice">Yes</span>
                <span v-else>No</span>
              </td>
              <td>
                <span v-if="address.address_1">{{ address.address_1 }}</span>
                <span v-if="address.address_2">, {{ address.address_2 }}</span>
                <span v-if="address.address_3">, {{ address.address_3 }}</span>
                <span v-if="address.address_4">, {{ address.address_4 }}</span>
                <span v-if="address.address_5">, {{ address.address_5 }}</span>
                <span v-if="address.postcode">, {{ address.postcode }}</span>
                <span v-if="address.country">, {{ address.country }}</span>
              </td>
              <td>
                <span
                  @click="addressInEdit = index, selectedAddress = address"
                  title="Edit address"
                  class="material-symbols-outlined icon-yellow icon-link"
                  >edit</span>
                <span
                  title="Delete address"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteAddress(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addAddress" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Name</label>
        <input type="text" v-model="newAddress.name">
      </div>
      <div class="cell small-12 medium-6">
        <label>Line One</label>
        <input type="text" v-model="newAddress.address_1">
      </div>
    </div>
    <div v-if="addAddress" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Line Two</label>
        <input type="text" v-model="newAddress.address_2">
      </div>
      <div class="cell small-12 medium-6">
        <label>Line Three</label>
        <input type="text" v-model="newAddress.address_3">
      </div>
    </div>
    <div v-if="addAddress" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Line Four</label>
        <input type="text" v-model="newAddress.address_4">
      </div>
      <div class="cell small-12 medium-6">
        <label>Line Five</label>
        <input type="text" v-model="newAddress.address_5">
      </div>
    </div>
    <div v-if="addAddress" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Postcode</label>
        <input type="text" v-model="newAddress.postcode">
      </div>
      <div class="cell small-12 medium-6">
        <label>Country</label>
        <input type="text" v-model="newAddress.country">
      </div>
    </div>
    <div v-if="addAddress" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>
          <input type="checkbox" v-model="newAddress.primary_address">
          Is this the primary address
        </label>
      </div>
      <div class="cell small-12 medium-6">
        <label>
          <input type="checkbox" v-model="newAddress.invoice">
          Is this the invoice address
        </label>
      </div>
    </div>
    <div v-if="addressInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Name</label>
        <input type="text" v-model="selectedAddress.name">
      </div>
      <div class="cell small-12 medium-6">
        <label>Line One</label>
        <input type="text" v-model="selectedAddress.address_1">
      </div>
    </div>
    <div v-if="addressInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Line Two</label>
        <input type="text" v-model="selectedAddress.address_2">
      </div>
      <div class="cell small-12 medium-6">
        <label>Line Three</label>
        <input type="text" v-model="selectedAddress.address_3">
      </div>
    </div>
    <div v-if="addressInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Line Four</label>
        <input type="text" v-model="selectedAddress.address_4">
      </div>
      <div class="cell small-12 medium-6">
        <label>Line Five</label>
        <input type="text" v-model="selectedAddress.address_5">
      </div>
    </div>
    <div v-if="addressInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Postcode</label>
        <input type="text" v-model="selectedAddress.postcode">
      </div>
      <div class="cell small-12 medium-6">
        <label>Country</label>
        <input type="text" v-model="selectedAddress.country">
      </div>
    </div>
    <div v-if="addressInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>
          <input type="checkbox" v-model="selectedAddress.primary_address">
          Is this the primary address
        </label>
      </div>
      <div class="cell small-12 medium-6">
        <label>
          <input type="checkbox" v-model="selectedAddress.invoice">
          Is this the invoice address
        </label>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          v-if="!addAddress && addressInEdit === -1"
          @click="addAddress = true"
          class="button small">Add Address</button>
        &nbsp;
        <button
          v-if="addressInEdit > -1"
          @click="updateAddress"
          class="button small">Save changes</button>
        &nbsp;
        <button
          v-if="addAddress && addressInEdit === -1"
          @click="saveAddress"
          class="button small">Save</button>
        &nbsp;
        <button @click="$emit('hideAddresses')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ClientsComponentsAddresses',
  props: [
    'addresses',
    'clientId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addAddress: false,
      addressInEdit: -1,
      newAddress: {
        country: 'UK',
      },
      selectedAddress: {},
    };
  },
  methods: {
    saveAddress() {
      const postData = {};
      postData.address = this.newAddress;
      postData.address.client_id = this.clientId;
      axios.post(`/addresses/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addAddress', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateAddress() {
      const postData = {};
      postData.address = this.selectedAddress;
      axios.post(`/addresses/update/${this.selectedAddress.id}.json?token=${this.token}`, postData)
        .then((response) => {
          const result = {};
          result.response = response.data;
          result.index = this.addressInEdit;
          this.$emit('updateAddress', result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAddress(index) {
      const addressId = this.addresses[index].id;
      axios.get(`/addresses/delete/${addressId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('deleteAddress', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
