<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addAccountHandler">
          <thead>
            <th>Name</th>
            <th>Job Title</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(accountHandler, index) in accountHandlers" :key="`ah_${index}`">
              <td>{{ accountHandler.first_name }} {{ accountHandler.lastname }}</td>
              <td>{{ accountHandler.job_title.title }}</td>
              <td><span
                  title="Remove account handler from client"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="removeHandler(index)"
                  >person_remove</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addAccountHandler" class="grid-x grid-padding-x">
      <div class="cell small-12">
        <select v-model="newAccountHandler">
          <option value="0">Please choose:</option>
          <option
            v-for="(user, index) in users"
            :key="`u_${index}`"
            :value="user.id">{{ user.name }} {{ user.jobTitle }}</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          v-if="!addAccountHandler"
          @click="addAccountHandler = true"
          class="button small">Add</button>
        &nbsp;
        <button
          v-if="addAccountHandler"
          @click="saveAccountHandler"
          class="button small">Save</button>
        &nbsp;
        <button @click="$emit('hideAccountHandlers')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ClientsComponentsAccountHandlers',
  props: [
    'accountHandlers',
    'clientId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addAccountHandler: false,
      newAccountHandler: 0,
      users: [],
    };
  },
  methods: {
    saveAccountHandler() {
      const postData = {};
      postData.accountHandlerId = this.newAccountHandler;
      postData.clientId = this.clientId;
      axios.post(`/clientsUsers/addAccountHandler.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addAccountHandler', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeHandler(index) {
      // eslint-disable-next-line
      const accountHandlerId = this.accountHandlers[index]._joinData.id;
      axios.get(`/clientsUsers/removeAccountHandler/${accountHandlerId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('removeAccountHandler', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsers() {
      axios.get(`/users/getList.json?token=${this.token}`)
        .then((response) => {
          this.users = response.data.users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
