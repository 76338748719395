<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addVeeva && veevaInEdit === -1">
          <thead>
            <th>Name</th>
            <th>Username</th>
            <th>Password</th>
            <th>Security</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(veeva, index) in veevas" :key="`c_${index}`">
              <td>{{ veeva.name }}</td>
              <td>{{ veeva.username }}</td>
              <td>{{ veeva.password }}</td>
              <td>{{ veeva.security }}</td>
              <td class="text-right">
                <span
                  title="Edit Veeva"
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="veevaInEdit = index, selectedVeeva = veeva"
                  >edit</span>
                <span
                  title="Delete Veeva"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteVeeva(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addVeeva" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Name</label>
        <input v-model="veeva.name" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Username</label>
        <input v-model="veeva.username" type="text">
      </div>
    </div>
    <div v-if="addVeeva" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Password</label>
        <input v-model="veeva.password" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Security</label>
        <input v-model="veeva.security" type="text">
      </div>
    </div>
    <div v-if="veevaInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Name</label>
        <input v-model="selectedVeeva.name" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Username</label>
        <input v-model="selectedVeeva.username" type="text">
      </div>
    </div>
    <div v-if="veevaInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Password</label>
        <input v-model="selectedVeeva.password" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Security</label>
        <input v-model="selectedVeeva.security" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          v-if="!addVeeva && veevaInEdit === -1"
          @click="addVeeva = true"
          class="button small">Add</button>
        &nbsp;
        <button
          v-if="addVeeva"
          @click="saveVeeva"
          class="button small">Save</button>
        &nbsp;
        <button
          v-if="veevaInEdit > -1"
          @click="updateVeeva"
          class="button small">Save changes</button>
        &nbsp;
        <button @click="$emit('hideVeevas')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ClientsComponentsVeevas',
  props: [
    'veevas',
    'clientId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addVeeva: false,
      veeva: {
        name: '',
        username: '',
        password: '',
        security: '',
      },
      veevaInEdit: -1,
      selectedVeeva: {},
    };
  },
  methods: {
    saveVeeva() {
      const postData = {};
      postData.veeva = this.veeva;
      postData.veeva.client_id = this.clientId;
      axios.post(`/veevaAccounts/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addVeeva', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateVeeva() {
      const postData = {};
      postData.veeva = this.selectedVeeva;
      const veevaId = this.selectedVeeva.id;
      axios.post(`/veevaAccounts/update/${veevaId}.json?token=${this.token}`, postData)
        .then((response) => {
          const result = {};
          result.response = response.data;
          result.index = this.veevaInEdit;
          this.$emit('updateVeeva', result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteVeeva(index) {
      const veevaId = this.veevas[index].id;
      axios.get(`/veevaAccounts/delete/${veevaId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('deleteVeeva', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
