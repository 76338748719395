<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addBrand && brandInEdit === -1">
          <thead>
            <th>Name</th>
            <th>Short Name</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(brand, index) in brands" :key="`b_${index}`">
              <td>{{ brand.name }}</td>
              <td>{{ brand.short_name }}</td>
              <td class="text-right">
                <span
                  title="Edit brand"
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="brandInEdit = index, selectedBrand = brand"
                  >edit</span>
                <span
                  title="Delete brand"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteBrand(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addBrand" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Brand name</label>
        <input v-model="brand.name" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Brand shortname</label>
        <input v-model="brand.short_name" type="text">
      </div>
    </div>
    <div v-if="brandInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Brand name</label>
        <input v-model="selectedBrand.name" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Brand shortname</label>
        <input v-model="selectedBrand.short_name" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          v-if="!addBrand && brandInEdit === -1"
          @click="addBrand = true"
          class="button small">Add</button>
        &nbsp;
        <button
          v-if="addBrand"
          @click="saveBrand"
          class="button small">Save</button>
        &nbsp;
        <button
          v-if="brandInEdit > -1"
          @click="updateBrand"
          class="button small">Save changes</button>
        &nbsp;
        <button @click="$emit('hideBrands')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ClientsComponentsBrands',
  props: [
    'brands',
    'clientId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addBrand: false,
      brand: {
        name: '',
        short_name: '',
      },
      brandInEdit: -1,
      selectedBrand: {},
    };
  },
  methods: {
    saveBrand() {
      const postData = {};
      postData.brand = this.brand;
      postData.brand.client_id = this.clientId;
      axios.post(`/brands/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addBrand', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateBrand() {
      const postData = {};
      postData.brand = this.selectedBrand;
      const brandId = this.selectedBrand.id;
      axios.post(`/brands/update/${brandId}.json?token=${this.token}`, postData)
        .then((response) => {
          const result = {};
          result.response = response.data;
          result.index = this.brandInEdit;
          this.$emit('updateBrand', result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteBrand(index) {
      const brandId = this.brands[index].id;
      axios.get(`/brands/delete/${brandId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('deleteBrand', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
