<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table v-if="!addParameter && parameterInEdit === -1">
          <thead>
            <th>Description</th>
            <th>Currency</th>
            <th>Deposit %</th>
            <th>Email</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(parameter, index) in invoice_parameters" :key="`c_${index}`">
              <td>{{ parameter.description }}</td>
              <td>{{ parameter.currency }}</td>
              <td>{{ parameter.deposit_percentage }}</td>
              <td>{{ parameter.email }}</td>
              <td class="text-right">
                <span
                  title="Edit invoice parameter"
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="parameterInEdit = index, selectedParameter = parameter"
                  >edit</span>
                <span
                  title="Delete invoice parameter"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteParameter(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="addParameter" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Description</label>
        <input v-model="parameter.description" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Currency</label>
        <input v-model="parameter.currency" type="text">
      </div>
    </div>
    <div v-if="addParameter" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Deposit %</label>
        <input v-model="parameter.deposit_percentage" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Email</label>
        <input v-model="parameter.email" type="text">
      </div>
    </div>
    <div v-if="parameterInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Descripton</label>
        <input v-model="selectedParameter.description" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Last name</label>
        <input v-model="selectedParameter.currency" type="text">
      </div>
    </div>
    <div v-if="parameterInEdit > -1" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Deposit %</label>
        <input v-model="selectedParameter.deposit_percentage" type="text">
      </div>
      <div class="cell small-12 medium-6">
        <label>Email</label>
        <input v-model="selectedParameter.email" type="text">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          v-if="!addParameter && parameterInEdit === -1"
          @click="addParameter = true"
          class="button small">Add</button>
        &nbsp;
        <button
          v-if="addParameter"
          @click="saveParameter"
          class="button small">Save</button>
        &nbsp;
        <button
          v-if="parameterInEdit > -1"
          @click="updateParameter"
          class="button small">Save changes</button>
        &nbsp;
        <button @click="$emit('hideParameters')" class="button small">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ClientsComponentsInvoiceParameters',
  props: [
    'invoice_parameters',
    'clientId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addParameter: false,
      parameter: {
        currency: '',
        deposit_percentage: 0,
        description: '',
        email: '',
      },
      parameterInEdit: -1,
      selectedParameter: {},
    };
  },
  methods: {
    saveParameter() {
      const postData = {};
      postData.parameter = this.parameter;
      postData.parameter.client_id = this.clientId;
      axios.post(`/invoiceParameters/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('addParameter', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateParameter() {
      const postData = {};
      postData.parameter = this.selectedParameter;
      const parameterId = this.selectedParameter.id;
      axios.post(`/invoiceParameters/update/${parameterId}.json?token=${this.token}`, postData)
        .then((response) => {
          const result = {};
          result.response = response.data;
          result.index = this.parameterInEdit;
          this.$emit('updateParameter', result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteParameter(index) {
      const parameterId = this.invoice_parameters[index].id;
      axios.get(`/invoiceParameters/delete/${parameterId}.json?token=${this.token}`)
        .then(() => {
          this.$emit('deleteParameter', index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
